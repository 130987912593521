import axios from 'axios';

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

let baseURL = process.env.baseURL || "https://topfloormusicapp.com/auth/api";

let instance = axios.create({
	baseURL
});

export const HTTP = instance;
