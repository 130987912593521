<style>
.gradient-custom {
    /* fallback for old browsers */
    background: #fccb90;
    
    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to bottom right, rgb(244 246 250 / 0%), rgb(66 69 104));
    
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to bottom right, rgb(244 246 250 / 0%), rgb(66 69 104));
    }
    
    .mask-custom {
    background: rgba(24, 24, 16, .2);
    border-radius: 2em;
    backdrop-filter: blur(15px);
    border: 2px solid rgba(255, 255, 255, 0.05);
    background-clip: padding-box;
    box-shadow: 10px 10px 10px rgba(46, 54, 68, 0.03);
    font-size: 18px;
    }
</style>
<template>
    <div class="livevideo bg-grey">
        <loader v-if="loading"></loader>
        <section class="live-video-wrapper top-spacing">
            <div class="container">
                <div class="row">
                    <div class="theater_container row">
                        
                        <div class="col-md-7 col-lg-7 pl-0 pr-0" id="main_player">
                            <!-- <img src="assets/images/video-poster.png"> -->
                            <div v-if="isYouTubeEmbeddedURL(url)" style="display: contents;">
                                <iframe  width="100%" height="100%" :src="url" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div v-else  style="display: contents;">
                                <video id="video"  width="100%" height="100%" controls poster="https://topfloormusicapp.com/assets/images/logo_offline.png"></video>
                                 
                            </div>
                            <!-- <iframe  width="100%" height="100%" :src="livevideo.url" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                        </div>
                        <!-- <div class="col-md-4 col-lg-3 pl-0 pr-0">
                            <div class="live-chat-wrapper white-back-chat">
                                <div class="live-chat-wrapper">
                                    <div class="chat-wrapper-live" id="allChat-div">
                                        <div class="live-data-content message_class" v-bind:class="[item.type == 'user' ? 'user-message' : 'admin-message']" v-for="item in allChats" :key="item.key">
                                            <div class="live-artist-data">
                                                <div class="chat-profile">
                                                    <span>
                                                        <img src="https://djangelsapp.com/djangelsapp/assets/img/avtar.png" />
                                                    </span>
                                                </div>
                                                <div class="name-message">
                                                    <span class="live-data live-name">
                                                        {{item.commentByName}} :
                                                    </span>
                                                    <span class="live-data live-message">
                                                        {{item.comment}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <form>
                                    <div class="form-group">
                                        <div class="current-user-live">
                                            <img :src="image"/>
                                        </div>
                                        <textarea v-model="itemValue" placeholder="Start Chat" required type="text" class="form-control" ></textarea>
                                    </div>
                                    <div  class="btn-group"><button type="button" class="btn btn-success" @click="sendMessage()">Send</button>
                                    </div>
                                </form>
                            </div>
                        </div> -->
                        <div class="col-md-5 col-lg-5 pl-0 pr-0"><ChatComponent /></div>
                        
                        
                    </div>

                    <div class="col-md-12 col-lg-12 pl-0 pr-0">
                        <div class="desc-live">
                            <div class="video-title-description">
                                <div class="live-video-tilte">
                                    <h5 id="stream_title"> {{ livevideo.name }}</h5>
                                </div>
                                <h5 class="sub-title">
                                    <p class="p-content" id="stream_desc">
                                        <span v-html="livevideo.description"></span>
                                    </p>
                                </h5>
                            </div>  
                        </div>
                    </div>
                    <hr style="width:100%; border:1px solid #462929"/>
                   



                    <div class="container">				
                        <div class="gride-container" style="margin-bottom: 40px;" v-if="livestream.length > 0">
                            <div class="gride-box">
                                <div @click="onCnangeVideo(livevideo.url,livevideo.name,livevideo.description)">
                                    <div class="loader-static">
                                        <div class="loader-dynamic">
                                            <img :src="livevideo.image"  @error="$event.target.src='assets/images/placeholder.png'">
                                        </div>
                                        <img src="assets/images/Square.png" class="stactic-img">
                                    </div>
                                    <div class="gride-content">
                                        <h6>{{ livevideo.name }}</h6>
                                    
                                    </div>
                                </div>
                            </div>
                            <div class="gride-box"  v-for="value in livestream" :key="value.livestream_id">
                                <div @click="onCnangeVideo(value.livestream_url,value.livestream_name,value.livestream_description)">
                                    <div class="loader-static">
                                        <div class="loader-dynamic">
                                            <img :src="value.livestream_image"  @error="$event.target.src='assets/images/placeholder.png'">
                                        </div>
                                        <img src="assets/images/Square.png" class="stactic-img">
                                    </div>
                                    <div class="gride-content">
                                        <h6>{{ value.livestream_name }}</h6>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    </div>
</template>
<script src="https://cdn.jsdelivr.net/npm/hls.js@latest"></script>
<script>
    import {HTTP} from '../../_helper/http-constants.js';
    // import ChatService from "../../services/firebaseService.js";
    import { EventEmmiter } from "../../eventEmmiter.js";
    import ChatComponent from './ChatComponent.vue';
    //import VueDPlayer from 'vue-dplayer';
    
    export default {
        name: "LiveVideo",
        props: ['url'],
        components: {
            ChatComponent,
        },
        // components: {
        //     VueDPlayer,
        // },
        data() {
            return {
                user: JSON.parse(window.localStorage.getItem('user')),
                livevideo: null,
                loading: false,
                name: '',
                image: '',
                userStaticImage: 'https://djangelsapp.com/djangelsapp/assets/img/avtar.png',
                itemValue: '',
                currentTime: '',
                livestream: '',
                allChats: [],                
            } 
        },
        methods: {
            getLiveVideo() {
                this.loading = true;
                HTTP.post('/livevideo',{
                    user_id: this.user.user_id
                }).then(res => {
                    if(res.data.status === 200){
                        this.livevideo = res.data.data[0];
                        this.url = this.livevideo.url;
                        //plyvideohls(this.url);
                        //alert("in");
                        //this.eventEmiterService.emitAudioPlayerLoaded(false);
                        if(this.isYouTubeEmbeddedURL(this.url)){
                            onPauseAudio();
                        }else{
                            setTimeout(() => {
                                plyvideohls(this.url);
                            },300);
                        }
                         
                        //alert("in2"); 
                        // this.eventEmmiterService.pauseSong(true);
                        // this.currentPlaying = 0;
                       
                        this.loading = false;
                    }else{
                        this.loading = false;
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                            icon: 'warning',
                            title: res.data.message
                        });
                    }
                }).catch(err => {
                    this.loading = false;
                    console.log(err);
                });
            },
            // sendMessage() {
            //     if(this.itemValue != '') {
            //     var today = new Date();
            //     // var today = new Date(date.toLocaleString('en-US', {}));
            //     var options = {
            //     hour: '2-digit', minute: '2-digit', timeZone: 'America/Toronto'
            //     };
            //     var timeOptions = {
            //     hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true, timeZone: 'America/Toronto'
            //     };
            //     var cuurentDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
            //     var cuurentTime = today.toLocaleTimeString('en-us', timeOptions);
            //     // if(cuurentTime.contains(' AM') == true) {

            //     // }
            //     cuurentTime = cuurentTime.replace(' AM', '');
            //     cuurentTime = cuurentTime.replace(' PM', '');
            //     const dateTime = cuurentDate + ' ' + cuurentTime;
            //     this.currentTime = today.toLocaleTimeString('en-us', options);
            //     var data = {
            //         comment: this.itemValue,
            //         commentByName: this.name,
            //         commentDate: dateTime,
            //         commentImageURL: this.image,
            //         time: this.currentTime,
            //         type: 'user'
            //     };
            //     console.log(data);
            //     ChatService.sendChat(data).then(() => {
            //         this.itemValue = '';
            //     });
                    
            //     }
            // },
            getlivestreamvideo() {
                    this.loading = true;
                    HTTP.get(`/livestream`).then(res => {
                    if (res.data.status === 200) {
                        this.livestream = res.data.data;
                        console.log("data-LIVES",res.data.data);
                        
                        //this.current_page = res.data.current_page;
                        //this.pages = res.data.last_page;
                        this.loading = false;
                    } else {
                        this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                        icon: 'warning',
                        title: res.data.message
                        });
                        this.loading = false;
                    }
                    }).catch(err => {
                    this.loading = false;
                    console.log(err)
                    });
                },
            onCnangeVideo(sources,name,description){
                //alert(sources);
                //this.livevideo = sources;
                //console.log("video",sources);
                //document.getElementById('main_player').contentDocument.location.reload(true);
                onPauseAudio();
                document.getElementById('app').scrollIntoView({ behavior: 'smooth' });

                if(this.isYouTubeEmbeddedURL(sources)){
               
                        document.getElementById("main_player").innerHTML = '<iframe  width="100%" height="100%" src="'+sources+'?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
                        
                        }else{
                            document.getElementById("main_player").innerHTML = '<video id="video"  width="100%" height="100%" controls></video>';
                            
                            plyvideohls(sources);
                            
                        }
                        document.getElementById("stream_title").innerHTML = name;
                        document.getElementById("stream_desc").innerHTML = '<span>'+description+'</span>';
                        
                    
                },
                    isYouTubeEmbeddedURL(url) {
                    const youtubeEmbeddedRegex = /youtube\.com\/embed\//;
                    //alert(youtubeEmbeddedRegex.test(url));
                    return youtubeEmbeddedRegex.test(url);
            },
            onDataChange(items) {
                let _allChats = [];
                items.forEach((item) => {
                    let key = item.key;
                    let data = item.val();
                    _allChats.push({
                        key: key,
                        comment: data.comment,
                        commentByName: data.commentByName,
                        commentDate: data.commentDate,
                        commentImageURL: data.commentImageURL,
                        time: data.time,
                        type: data.type
                    });
                });
                this.allChats = _allChats;
                setTimeout(() => {
                    var objDiv = document.getElementById('allChat-div');
                    objDiv.scrollTop = objDiv.scrollHeight
                }, 0)
            },
            
        },
        mounted() {
            this.user = JSON.parse(window.localStorage.getItem('user'));
            this.getLiveVideo();
            this.getlivestreamvideo();
            // if(this.user != null) {
            //     if(this.user.fullname != '' && this.user.fullname != null) {
            //         this.name = this.user.fullname
            //     } else {
            //         this.name = 'User'
            //     }

            //     if(this.user.image != '' && this.user.image != null) {
            //         this.image = this.user.image
            //     } else {
            //         this.image = this.userStaticImage
            //     }
            // } else {
            this.name = 'User';
            if(this.user.image != '' && this.user.image != null) {
                    this.image = this.user.image
                } else {
                    this.image = this.userStaticImage;
                }
            
            // }
            // ChatService.getAllChats().on("value", this.onDataChange);

            

            
        },
        beforeDestroy() {
            // ChatService.getAllChats().off("value", this.onDataChange);
        }
    }
    // this is new socket script for chats.....................

    
</script>