<!-- ChatComponent.vue -->
<template>
    <section class="gradient-custom">
      <div class="container py-2">
        <div class="col-md-12 col-lg-12 col-xl-12" id="scroll-div" style="height: 425px; overflow-y: auto;" ref="scrollDiv">
          <ul class="list-unstyled text-white" ref="chatMessages">
            <li v-for="(message, index) in messages" :key="index" :class="{'d-flex justify-content-between': true, 'user-message': message.username === user.username, 'admin-message': message.username !== user.username}">
                <img v-if="message.username !== user.username && message.user_type === 1" :src="adminImage" alt="avatar" class="rounded-circle d-flex align-self-start me-3 shadow-1-strong" style="width: 20%;" />
                <img v-if="message.username !== user.username && message.user_type === 2" :src="dummyProfile" alt="avatar" class="rounded-circle d-flex align-self-start me-3 shadow-1-strong" style="width: 20%;" />
              <div class="card mask-custom w-100" style="height: auto;">
                <div class="card-header d-flex justify-content-between p-3" style="border-bottom: 1px solid rgba(255,255,255,.3);height: 50px;">
                  <p class="fw-bold mb-0" style="color: white;">{{ message.username }}</p>
                  <p class="text-light small mb-0"><i class="far fa-clock"></i> {{ message.date_added }}</p>
                </div>
                <div class="card-body" style="padding: 15px;">
                  <p class="mb-0" style="font-size: 20px; color: white; text-align: left;">
                    {{ message.message }}
                  </p>
                </div>
              </div>
              <img v-if="message.username === user.username" :src="dummyProfile" alt="avatar" class="rounded-circle d-flex align-self-start ms-3 shadow-1-strong" style="width: 20%;" />
              
            </li>
          </ul>
        </div>
        <div class="form-outline form-white" style="display: flex; margin-top: 15px;">
          <input v-model="newMessage" type="text" name="content" class="form-control" placeholder="Your message..." />
          <button @click="sendMessage()" type="button" class="btn btn-light btn-lg btn-rounded float-end" id="chat-message-submit">Send</button>
        </div>
      </div>
    </section>
  </template>
  
  <script>
    import {HTTP} from '../../_helper/http-constants.js';
    const host = HTTP.defaults.baseURL; // This will give you the host part of the URL
    console.log(host);

    // Alternatively, if you want to extract only the host (without protocol and path):
    const parser = document.createElement('a');
    parser.href = HTTP.defaults.baseURL;
    const hostOnly = parser.host;
    const current_user = JSON.parse(window.localStorage.getItem('user'))
    var current_user_id = 0;
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    try {
        current_user_id = current_user.user_id;
    }
    catch(err) {
        current_user_id = 0;
    }
  export default {
    data() {
      return {
        roomName: 'livevideochat',
        userName: `user_${current_user_id}`,
        currentUser: `user_${current_user_id}`,
        chatSocket: null,
        user: { username: `user_${current_user_id}` }, // Replace with actual user data
        messages: [], // Array to store chat messages
        newMessage: "", // Holds the new message to be sent
        adminImage : "",
        dummyProfile: "",
        };
    },
    mounted() {
      // Set up your chat socket when the component is mounted
      this.chatSocket = new WebSocket(
        'wss://' + hostOnly + '/ws/' + this.roomName + '/'
      );
      this.getoldchat();
      this.scrollToBottom();
        var container = this.$el.querySelector("#scroll-div");
        container.scrollTop = container.scrollHeight;
  
      // Set up event listeners for chat socket
      this.chatSocket.onclose = this.onClose;
      this.chatSocket.onmessage = this.onMessage;
  
      // Additional setup if needed
      // ...
    },
    methods: {
            useData() {
        console.log(this.data);
        // Perform actions with this.data
        },
        onClose(e) {
            console.log('Chat socket closed:', e);
        },
        onMessage(e) {
            const data = JSON.parse(e.data);
            // Process the incoming message and update your chat UI
            this.updateChatUI(data);
            this.scrollToBottom();
        },
        sendMessage() {
        // Implement the logic to send a new message
        if (this.newMessage.trim() !== "") {
            const currentTime = Date.now();
            const formattedTimestamp = new Intl.DateTimeFormat('en-US', options).format(currentTime);
            const newMessageObj = JSON.stringify({
                'id': this.messages.length + 1,
                'message': this.newMessage,
                'username': this.user.username,
                'room': this.roomName,
                'date_added': formattedTimestamp
            })
            const newMessagePost = {
            id: this.messages.length, // Replace with an appropriate ID generation logic
            username: this.user.username,
            message: this.newMessage,
            date_added: formattedTimestamp,
            room: this.roomName,
            user_type: 2
            };
            this.chatSocket.send(newMessageObj);
            this.messages.push(newMessagePost);
            // Add logic to send the message to the server or wherever needed
            // For now, we're just updating the local messages array
            this.newMessage = ""; // Clear the input field
            this.scrollToBottom();
        }
        },
        scrollToBottom() {
        // Function to scroll to the bottom of the chat messages
        //   this.$refs.scrollDiv.scrollTop = this.$refs.scrollDiv.scrollHeight;
            var container = this.$el.querySelector("#scroll-div");
            container.scrollTop = container.scrollHeight;
        },
        getoldchat() {
            this.loading = true;
            HTTP.get(`/oldchatload`).then(res => {
            if (res.data.status === 200) {
                const newData = res.data.data;
                this.adminImage = res.data.thumbnailImage;
                this.dummyProfile = res.data.dummyProfile;
                newData.forEach(msgGetJson => {
                const newMessageGet = {
                    id: msgGetJson.id,
                    username: msgGetJson.username,
                    message: msgGetJson.message,
                    date_added: msgGetJson.date_added,
                    user_type: msgGetJson.user_type,
                    room: msgGetJson.room,
                };

                // Push the formatted message to the messages array
                this.messages.push(newMessageGet);
                });
                

                this.loading = false;
            } else {
                this.$swal.fire({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
                icon: 'warning',
                title: res.data.message
                });
                this.loading = false;
            }
            }).catch(err => {
            this.loading = false;
            console.log(err)
            });
            },
            updateChatUI(data) {
                const msgGetJson = data;
                const newMessageGet = {
                id: this.messages.length+1, // Replace with an appropriate ID generation logic
                username: msgGetJson.username,
                message: msgGetJson.message,
                date_added: msgGetJson.date_added,
                user_type: msgGetJson.user_type,
                room: this.roomName
                };
                this.messages.push(newMessageGet);
                // Implement the logic to update your chat UI
                // Append the message to the chat container
                // You can use Vue data properties to store chat messages and bind them in your template
                // ...
            },
        },
    };
  </script>
  